<template>
  <div
    v-if="show"
    ref="confimration_modal"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="confirmModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--capital">
              confirmation
            </span>
            <a
              class="modal__close modal__close--white d-flex align-items-center justify-content-between"
              @click="$emit('close')"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
          <span class="modal__header__subtitle text--capital"> </span>
        </div>
        <div class="modal__content">
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Undo Begin Maturity Period
              </h5>
              <p>
                Please confirm that you want to undo maturity period for this
                account
              </p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="$emit('close')"
                >
                  cancel
                </button>

                <button
                  data-dismiss="#confirmModal"
                  class="button form__button form__button--lg save-changes fitrem"
                  v-if="loading"
                >
                  <div class="spinner-border texxt-light" role="status"></div>
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button form__button form__button--lg save-changes modal__confirm__button--delete fitrem"
                  @click="onUndoConfirm"
                  v-else
                >
                  Undo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "undoBeginMaturity",
  props: {
    show: Boolean,
    close: Function,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onUndoConfirm() {
      this.loading = true;
      ApiService.put(
        `DepositAccount/undomaturityperiod/${this.$route.params.id}`
      )
        .then((response) => {
          if (response.data.status !== false) {
            this.loading = false;
            this.$emit("undo-maturity-modal", response.data.message);
          } else {
            this.loading = false;
            this.$emit("error-undo-modal", response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("error-undo-modal", error.response.message);
        });
    },
  },
};
</script>

<style scoped>
.fitrem {
  min-width: 15rem;
}
</style>
